<template>
<div class="inner-template">
  <h1 class="ml-4 my-4">Meetings</h1>
  <!-- <h1 class="ml-2 my-4"><v-icon class="mr-2" color="primary">mdi-calendar</v-icon>Meetings</h1> -->
    <div class="settings component" :class="{'mobile': $vuetify.breakpoint.smAndDown}">
      <v-container fill-height fluid class="align-start">
        <v-card color="white" class="ma-0 col-12 mb-2" elevation="4">
            <vc-calendar
            ref="calendar"
            is-expanded
            @dayclick="onDayClick"
            @update:to-page="onMonthChange"
            :from-date="startDate"
            trim-weeks
            :attributes.sync="attributes"
            />

        <div class="dates col-12 pa-0 pt-4">

          <div class="" v-if="$isEmpty(meetings) && $isEmpty(proposals)">
            <div class="text-center">Loading all meetings..</div>
            <v-progress-linear
            indeterminate
            color="secondary"
            ></v-progress-linear>
          </div>
          <!-- meetings -->
          <div class="" v-else>
            <calendar-item
            :activeMonth="activeMonth"
            :proposals="propArr"
            :meetings="meetingArr"
            :propDateArr="propDateArr"
            :meetingDateArr="meetingDateArr"
            @goto-today="goToToday">
          </calendar-item>
          </div>
        </div>
      </v-card>
      </v-container>
    </div>
</div>
</template>

<script>
import { cloud, storage, db } from '@/firebase';
import { mapGetters } from 'vuex'
const calendarItem = () => import("@/components/shared/calendar-item.vue");
// import Calendar from 'v-calendar/lib/components/calendar.umd'

export default {
  name: "Calendar",
  components:{calendarItem},
  computed:{
     getPercentages(arr){
        return true;
    },
    userId: function (){ return this.$store.state.userId},
    user: function (){ return this.$store.state.user},
    meetings: function (){ return this.$store.state.meetings},
    meetingsLoaded: function (){ return this.$store.state.meetingsLoaded},
    meetingArr: function(){
      let vThis = this;
      let dateArr = [];
      this.meetings.forEach(item =>{
          let date = {
            id: item.id,
            data: item.data,
            date: vThis.$date.utc(item.data.accepted_Date).local().toDate()
          }
        dateArr.push(date);
        // dateArr = [...dateArr, ...date];
      })
      return dateArr;
    },
    meetingDateArr: function(){
      let vThis = this;
      let dateArr = [];
      this.meetingArr.forEach(item =>{
          item['kind'] = 'meeting';
          item['sendASAP'] = false;
          vThis.openMeetings.push(item);
          process.env.NODE_ENV === "development" ? console.log( 'vThis.$date.utc(item.data.accepted_Date).local().toDate()', item.data.accepted_Date, vThis.$date.utc(item.data.accepted_Date).local().toDate() ) : null;
          dateArr.push(vThis.$date.utc(item.data.accepted_Date).local().toDate())
      })
      return dateArr;
    },
    proposals: function (){ return this.$store.state.proposals},
    proposalsLoaded: function (){ return this.$store.state.proposalsLoaded},
    propArr: function(){
      let vThis = this;
      let dateArr = [];
      let dates = [];
      this.proposals.forEach(item =>{
        dates = item.data.dates.reduce(function(filtered, i){
          if(i.rejected === false){
            process.env.NODE_ENV === "development" ? console.log( 'prop reduce....', i ) : null;
            let newItem = {}
            newItem['id']= item.id;
            newItem['data'] = item.data;
            newItem['date'] = i.date === 'sendASAP' ? vThis.$date().startOf('date').toDate() : vThis.$date.utc(i.date).local().toDate();
            newItem['sendASAP'] = i.date === 'sendASAP' ? true : false;
            filtered.push(newItem);
          }
          return filtered;
        }, [])
        dateArr = [...dateArr, ...dates]
        process.env.NODE_ENV === "development" ? console.log( 'dates', dates, dateArr ) : null;
      })
      return dateArr;
    },
    propDateArr: function(){
      let vThis = this;
      let dateArr = [];
      process.env.NODE_ENV === "development" ? console.log( 'propDAteArr', this.propArr ) : null;
      this.propArr.forEach(item =>{
        if(item.rejected !== true){
          process.env.NODE_ENV === "development" ? console.log( 'propDateArr', item ) : null;
          item['kind'] = 'proposal';
          process.env.NODE_ENV === "development" ? console.log( 'vThis.$date.utc(item.data.date).local().toDate()', item.data.date, vThis.$date.utc(item.data.date).local().toDate() ) : null;
          dateArr.push(vThis.$date.utc(item.date).local().toDate())
          vThis.openProposals.push(item);

        }
      })
      return dateArr;
    },
  },
  data(){
    return{
      startDate: null,
      activeMonth:null,
      attributes:[],
      loadProps: null,
      openProposals:[],
      openProposalDates:[],
      openMeetings:[],
      isLoaded: false,
      selectedTab: 0,
      snackbar:{
        show:false,
      },
      tabs:[
      {icon:"mdi-account",text:"Overview"},
      {icon:"mdi-bank",text:"Billing"},
      {icon:"mdi-calendar-month-outline",text:"Calendar"},
      ],
      avatar: "https://avataaars.io/?accessoriesType=Prescription02&avatarStyle=Transparent&clotheColor=Gray02&clotheType=Hoodie&eyeType=Happy&eyebrowType=UpDownNatural&facialHairColor=BlondeGolden&facialHairType=MoustacheMagnum&graphicType=Resist&hairColor=BrownDark&hatColor=Gray01&mouthType=Grimace&skinColor=Pale&topType=ShortHairShortWaved",
    }
  },
  watch:{
    '$route': 'getUser',
    proposalsLoaded(val){
      if(val){this.loadCalAttr();}
    }
  },
  methods:{
    setupCal(){
      let vThis = this;
      this.$store.dispatch('getCurrentUser').then(user => {
        vThis.getMeetings(vThis.userId);
        vThis.getProposals(vThis.userId);
        vThis.loadCalAttr();
      })
    },
    //load meetingsLoaded
    getProposals(userId){
      let vThis = this;
      this.$store.dispatch('getProposals', {userId: userId, flag: 'active'});
    },
    getMeetings(userId){
      let vThis = this;
      this.$store.dispatch('getMeetings', {userId: userId});
    },
    onDayClick(day){

    },
    onMonthChange(data){
      process.env.NODE_ENV === "development" ? console.log( 'data from month', data ) : null;
      this.activeMonth = data;
      this.loadCalAttr();
    },
    loadCalAttr(){
      this.attributes = [];
      this.attributes.push({key: 'today', highlight: {color: 'gray', fillMode: 'outline', class: 'todayCalBG', contentClass: 'todayCal'}, dates: new Date()})
      this.attributes.push({dot: 'pink', dates: this.propDateArr})
      this.attributes.push({dot: 'blue', dates: this.meetingDateArr})
    },
    goToToday(data){
          // Get the calendar ref
    const calendar = this.$refs.calendar
    // Moves to January, 1983
    calendar.move({ month: data, year: this.$date().year() })
    }
  },
  created(){
    this.activeMonth = {month:this.$date().month(), year:this.$date().year()};
    this.startDate = this.$date().startOf('month').toDate();
    // this.loadCalAttr();
    this.setupCal()
  },
  beforeRouteUpdate (to, from, next) {
    // process.env.NODE_ENV === "development" ? console.log( 'to, from, next', to.params.userName, from.params.userName ) : null;
    // this.loadCalAttr();
    this.setupCal()
    next();

  }
};
</script>


<style lang="scss">
.settings.component{
  // background-color: #f2f2f2;
  position: relative;

  .profile-photo{
    .profile-img{
      max-width: 100%;
      border-radius: 6px;
      // border: 3px solid white;
    }
    h2{
      line-height: 95%;
    }
    .company-links{
      text-decoration: none;
    }
  }
  .header{
    position: relative;
    .name{
      line-height: 30px;
    }
    .edit{
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
  .v-card{
    .v-card__text{
      position: relative;
      .editBtn{
        position: absolute;
        right: 30%
      }
    }
  }
  .vc-pane-container{
    padding-bottom: 10px;
    .vc-day{
      .vc-day-content{
        color: #666;
      }
    }
    .vc-day.is-today .todayCal{
      // color: var(--v-primary-base)!important;
      color: #aaa!important;
    }
    .todayCalBG{
      border-color: #ccc!important;
    }
  }
  &.mobile{
    padding-top:0;
    padding-left: 0;
    .container{
      padding: 0;
      .row {
        margin: 0;
        > div{
          padding: 0;
        }
      }
      .v-card{
        padding-bottom: 12px;
      }
    }
    .profile-details{
      .profile-img{
        // max-width: 80vw;
        // border-radius: 0;
        // border: none;
      }
    }
    .meet-me.v-btn{
      // margin-top: -20px;
      border-radius: 6px;
      // text-shadow: 1px 1px 5px rgba(0,0,0,.60);
      background-color: rgba(0,0,0,.35)!important;
      border: 3px solid white!important;
    }
  }
}

</style>
